import { useSelector } from "react-redux";
import { crm } from "../../../store/cabinetApi/crm";
import { 
  AppCrmContactFieldTemplatesCreateApiArg,
  CompanyCategory
} from "../../../store/cabinetApi/generated/crm";
import CRM from "./CRM";
import { RootState } from "../../../store";


const CRMSettingsContainer = () => {
  
  const user = useSelector((state: RootState) => state.auth.user);

  const { data: fieldTemplatesData } = crm.useAppCrmContactFieldTemplatesListQuery({ limit: 1000 });
  const [createFieldTemplate] = crm.useAppCrmContactFieldTemplatesCreateMutation();
  const [updateFieldTemplate] = crm.useAppCrmContactFieldTemplatesPartialUpdateMutation();
  const [createFieldTemplateChoice] = crm.useAppCrmContactFieldChoicesCreateMutation();
  const [deleteFieldTemplate] = crm.useAppCrmContactFieldTemplatesDestroyMutation();
  const [
    createCompanyCategory,
    { isLoading: isCreatingContactOrgCategory }
  ] = crm.useAppCrmCompanyCategoriesCreateMutation();
  const [
    updateCompanyCategory,
    { isLoading: isUpdatingContactOrgCategory }
  ] = crm.useAppCrmCompanyCategoriesPartialUpdateMutation();
  const [
    deleteCompanyCategory,
    { isLoading: isDeletingContactOrgCategory }
  ] = crm.useAppCrmCompanyCategoriesDestroyMutation();
  const {data} = crm.useAppCrmCompanyCategoriesListQuery({
    limit: 100,
  });
  
  const organizationContactCategories: {[key: number]: CompanyCategory} = Object.fromEntries(
    data?.data?.map(
      (category) => [category.id, category]
    )  || []
  );

  
  const handleAddFieldTemplate = async (contact: AppCrmContactFieldTemplatesCreateApiArg) => {
    const res = await createFieldTemplate(contact);
    if (contact.contact_field_choices.length && res.data && "id" in res.data) {
      // TODO: update this logic when backend support bulk endpoints
      const choicesPromise = contact.contact_field_choices.map(choice => (
        createFieldTemplateChoice({
          ...choice,
          contact_field_template: res.data.id,
        })
      ));
      await Promise.all(choicesPromise);
    }

    return res;
  };

  const handleCreateCompanyCategory = async (category: CompanyCategory) => {
    createCompanyCategory(category);
  };

  const handleUpdateCompanyCategory = async (category: CompanyCategory) => {
    updateCompanyCategory({id: category.id, patchedCompanyCategory: category});
  };

  const handleDeleteCompanyCategory = async (category: CompanyCategory) => {
    deleteCompanyCategory(category.id);
  };

  return (
    <CRM
      fieldTemplates={fieldTemplatesData?.data || []}
      onAddFieldTemplate={handleAddFieldTemplate}
      onUpdateFieldTemplate={updateFieldTemplate}
      onDeleteFieldTemplate={deleteFieldTemplate}
      isUpdatingContactOrgCategory={isUpdatingContactOrgCategory}
      isCreatingContactOrgCategory={isCreatingContactOrgCategory}
      isDeletingContactOrgCategory={isDeletingContactOrgCategory}
      user={user}
      organizationContactCategories={organizationContactCategories}
      createCompanyCategory={handleCreateCompanyCategory}
      updateCompanyCategory={handleUpdateCompanyCategory}
      deleteCompanyCategory={handleDeleteCompanyCategory}
    />
  );
};

export default CRMSettingsContainer;